import { BriefcaseMoneyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import { InvestorStrategyListItem } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import { useOpenLink } from '@gain/utils/router'
import Hidden from '@mui/material/Hidden'
import React from 'react'

import {
  MobileListItem,
  MobileListItemIcon,
  MobileListItemSecondaryValue,
  MobileListItemText,
} from '../../common/list-mobile'
import Logo from '../../common/logo'
import ColumnPickerButton from '../../features/column-picker/column-picker-button'
import { useFilterModelQueryParam } from '../../features/filter/filter-bar'
import {
  INVESTOR_STRATEGY_DEFAULT_FILTERS,
  INVESTOR_STRATEGY_FILTER_MAP,
  INVESTOR_STRATEGY_FILTER_MENU,
} from '../../features/investor-strategy/investor-strategy-filter-bar'
import filterToInvestorStrategyTableColumnMapping from '../../features/investor-strategy/investor-strategy-table/filter-to-investor-strategy-table-column-mapping'
import { investorStrategyTableColumnNames } from '../../features/investor-strategy/investor-strategy-table/investor-strategy-table-columns'
import useInvestorStrategyListViewColumns from '../../features/investor-strategy/investor-strategy-table/use-investor-strategy-list-view-columns'
import ListView from '../../features/list-view'
import useActiveFilterColumns from '../../features/list-view/use-active-filter-columns'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import { generateInvestorStrategyPagePath } from '../investor-strategy'

export default function InvestorListPageStrategyTab() {
  const openLink = useOpenLink()
  const isXs = useIsXs()

  useTrackPageView('investor', { tab: 'strategies', requireTab: true, list: true })

  const [filterModel] = useFilterModelQueryParam()
  const activeFilterColumns = useActiveFilterColumns(
    filterModel,
    INVESTOR_STRATEGY_FILTER_MAP,
    investorStrategyTableColumnNames,
    filterToInvestorStrategyTableColumnMapping
  )
  const columns = useInvestorStrategyListViewColumns(activeFilterColumns)

  return (
    <>
      <Head>
        <title>Investors - Strategies</title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={'Strategies'}
          variant={'list'}
        />
      )}
      <ListView
        addFilterMenu={INVESTOR_STRATEGY_FILTER_MENU}
        defaultFilterModel={INVESTOR_STRATEGY_DEFAULT_FILTERS}
        defaultSort={[listSort<InvestorStrategyListItem>('assetsTotal', 'desc')]}
        filterBarSearchLabel={'Strategy name'}
        filterBarSearchPlaceholder={'E.g. Compass fund'}
        filterConfigMap={INVESTOR_STRATEGY_FILTER_MAP}
        inline={isXs}
        method={'data.listInvestorStrategies'}
        renderPageActions={() => (
          <Hidden mdDown>
            <ColumnPickerButton
              activeFilterColumns={activeFilterColumns}
              columnConfigId={'investorStrategy'}
            />
          </Hidden>
        )}
        sm={{
          variant: 'virtual-table',
          VariantProps: {
            columns,
            onRowClick({ row }, event) {
              openLink(
                generateInvestorStrategyPagePath({
                  investorId: row.investorId,
                  investorName: row.investorName,
                  strategyId: row.strategyId,
                  strategyName: row.strategyName,
                }),
                event
              )
            },
          },
        }}
        xs={{
          variant: 'list',
          VariantProps: {
            headerProps: {
              title: 'Name',
              secondaryTitle: 'Companies',
            },
            renderListItem: (item) => (
              <MobileListItem
                key={item.strategyId}
                disableDivider>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<BriefcaseMoneyIcon />}
                    size={40}
                    src={item.investorLogoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.strategyName}
                  secondary={item.investorName}
                />
                <MobileListItemSecondaryValue>{item.assetsTotal}</MobileListItemSecondaryValue>
              </MobileListItem>
            ),
          },
        }}
        disableOrFilter
      />
    </>
  )
}
