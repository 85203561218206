import { PieChartIcon, Strategy2Icon } from '@gain/components/icons'
import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { compareNumberDesc } from '@gain/utils/common'
import { useMemo } from 'react'

import { ChartGroupByConfig } from '../../../common/chart/chart-groups'
import {
  ASSET_CHART_GROUP_COUNTRY,
  ASSET_CHART_GROUP_US_STATES,
  ASSET_CHART_GROUPS,
} from './asset-group-by-configs'

function getIndustrySegmentGroup(industrySegments?: IndustryMarketSegmentListItem[]) {
  if (industrySegments && industrySegments.length > 0) {
    const assetIdToSegmentIdMap = industrySegments.reduce((acc, segment) => {
      segment.linkedAssetIds.forEach((id) => (acc[id] = segment.id))
      return acc
    }, {} as Record<number, number>)

    // The only reason why assets are not linked to a segment is because they are
    // manually added in the benchmarking tool.
    const defaultSegmentName = 'Manually added'

    const segmentIdToSegmentNameMap = industrySegments.reduce((acc, segment) => {
      return {
        ...acc,
        [segment.id]: segment.name || defaultSegmentName,
      }
    }, {} as Record<number, string>)

    const segmentMap = industrySegments.reduce(
      (acc, current) => ({
        ...acc,
        [current.id]: current,
      }),
      {} as Record<number, IndustryMarketSegmentListItem>
    )

    const group: ChartGroupByConfig<AssetListItem, number | null> = {
      label: 'Segment',
      getValue: (asset) =>
        assetIdToSegmentIdMap[asset.id] ? assetIdToSegmentIdMap[asset.id] : null,
      getLabel: (value) =>
        (value !== null && segmentIdToSegmentNameMap[value]) || defaultSegmentName,
      compare: (groupA, groupB) => {
        const aRevenue =
          typeof groupA.value === 'number' ? segmentMap[groupA.value].totalRevenueEur : 0
        const bRevenue =
          typeof groupB.value === 'number' ? segmentMap[groupB.value].totalRevenueEur : 0
        return compareNumberDesc(aRevenue, bRevenue)
      },
      icon: PieChartIcon,
    }

    return group as ChartGroupByConfig<AssetListItem>
  }

  return null
}

function getInvestorStrategyGroup(investorStrategies?: InvestorProfileStrategy[]) {
  if (!investorStrategies || investorStrategies.length === 0) {
    return null
  }

  const defaultStrategyName = investorStrategies.length > 0 ? 'Unknown' : 'Undefined'

  const assetIdToStrategyIdMap = investorStrategies.reduce((acc, strategy) => {
    strategy.assetIds.forEach((id) => (acc[id] = strategy.id || 0))
    return acc
  }, {} as Record<number, number>)

  const strategyIdToStrategyNameMap = investorStrategies.reduce((acc, strategy) => {
    return {
      ...acc,
      [strategy.id]: strategy.name || defaultStrategyName,
    }
  }, {} as Record<number, string>)

  const strategyMap = investorStrategies.reduce(
    (acc, current) => ({
      ...acc,
      [current.id]: current,
    }),
    {} as Record<number, InvestorProfileStrategy>
  )

  const group: ChartGroupByConfig<AssetListItem, number | null> = {
    label: 'Strategy',
    getValue: (asset) =>
      assetIdToStrategyIdMap[asset.id] ? assetIdToStrategyIdMap[asset.id] : null,
    getLabel: (value) =>
      (value !== null && strategyIdToStrategyNameMap[value]) || defaultStrategyName,
    compare: (groupA, groupB) => {
      const aRevenue = typeof groupA.value === 'number' ? strategyMap[groupA.value].assetCount : 0
      const bRevenue = typeof groupB.value === 'number' ? strategyMap[groupB.value].assetCount : 0
      return compareNumberDesc(aRevenue, bRevenue)
    },
    icon: Strategy2Icon,
  }

  return group as ChartGroupByConfig<AssetListItem>
}

export default function useGroupByOptions(
  industrySegments?: IndustryMarketSegmentListItem[],
  investorStrategies?: InvestorProfileStrategy[],
  assets?: AssetListItem[]
) {
  return useMemo(() => {
    const copy = ASSET_CHART_GROUPS.slice()

    const industrySegmentGroup = getIndustrySegmentGroup(industrySegments)
    if (industrySegmentGroup) {
      copy.unshift(industrySegmentGroup)
    }

    const investorStrategyGroup = getInvestorStrategyGroup(investorStrategies)
    if (investorStrategyGroup) {
      copy.unshift(investorStrategyGroup)
    }

    let dividerAdded = false

    // If any asset is not from the US, add an option to group by Country
    if (assets && assets.some((asset) => asset.region !== 'US')) {
      copy.push({
        ...ASSET_CHART_GROUP_COUNTRY,
        showDividerAbove: true,
      } as ChartGroupByConfig<AssetListItem>)
      dividerAdded = true
    }

    // If any asset is from the US, add an option to group by US States
    if (assets && assets.some((asset) => asset.region === 'US')) {
      copy.push({
        ...ASSET_CHART_GROUP_US_STATES,
        showDividerAbove: dividerAdded === false,
      } as ChartGroupByConfig<AssetListItem>)
    }

    return copy
  }, [industrySegments, investorStrategies, assets])
}
