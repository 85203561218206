import { useDealList } from '@gain/api/app/hooks'
import { AssetList, DealListItem, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { createListFilterForPastFiveYears, listFilter, listFilters } from '@gain/rpc/utils'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'

export function useInvestorStrategyDealCount(investor: Investor, strategy: InvestorStrategy) {
  const swrDeals = useDealList({
    filter: listFilters<DealListItem>(
      listFilter('buyerInvestorIds', '=', investor.id),
      listFilter('buyerStrategyIds', '=', strategy.id),
      createListFilterForPastFiveYears('announcementDate')
    ),
    limit: 0,
  })

  return swrDeals.data?.counts.filtered || null
}

export function useInvestorStrategyAddOnCount(assets: AssetList) {
  const swrDeals = useDealList(() => {
    if (assets.items.length === 0) {
      return null
    }

    return {
      filter: listFilters<DealListItem>(
        listFilter(
          'buyerAssetIds',
          '=',
          assets.items.map((item) => item.id)
        ),
        createListFilterForPastFiveYears('announcementDate')
      ),
      limit: 0,
    }
  })

  return swrDeals.data?.counts.filtered || null
}

export function useInvestorStrategyDryPowder(strategy: InvestorStrategy): string | null {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  if (strategy.dryPowderMinEur === null || strategy.dryPowderMaxEur === null) {
    return null
  }

  return formatCurrencyRange(strategy.dryPowderMinEur, strategy.dryPowderMaxEur, {
    round: 'estimate',
  })
}
