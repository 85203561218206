import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'
import React from 'react'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorFundraisingCard from '../../investor/route-summary/investor-fundraising-card'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'
import InvestorStrategyDealsCard from './investor-strategy-deals-card'
import InvestorStrategyFundsCard from './investor-strategy-funds-card'
import InvestorStrategyInformationCard from './investor-strategy-information-card'
import InvestorStrategyMetricsCard from './investor-strategy-metrics-card'
import InvestorStrategyOwnershipCard from './investor-strategy-ownership-card'
import InvestorStrategyRegionCard from './investor-strategy-region-card'
import InvestorStrategySectorCard from './investor-strategy-sector-card'
import InvestorStrategyShareFeedbackAlert from './investor-strategy-share-feedback'

export interface RouteSummaryProps {
  investor: Investor
  strategy: InvestorStrategy
  assets: AssetList
}

export default function RouteSummary({ investor, strategy, assets }: RouteSummaryProps) {
  const params = useInvestorStrategyPageParams()

  useTrackPageView('investor_strategy', {
    id: strategy.id,
    tab: 'summary',
    requireTab: true,
  })

  const hasInformationCard =
    strategy.description ||
    strategy.investmentTicketSizeMax ||
    strategy.evRangeMin ||
    strategy.evRangeMax ||
    strategy.ebitdaRangeMin ||
    strategy.ebitdaRangeMax ||
    strategy.preferredEquityStakeMinPct ||
    strategy.preferredEquityStakeMaxPct

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Summary
        </title>
      </Head>

      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid size={12}>
          <InvestorStrategyMetricsCard
            assets={assets}
            investor={investor}
            strategy={strategy}
          />
        </Grid>

        {hasInformationCard && (
          <>
            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategyInformationCard strategy={strategy} />
            </Grid>

            <Grid
              size={{ xs: 12, lg: 8 }}
              spacing={2}
              container>
              <Grid size={{ xs: 12, lg: 6 }}>
                <InvestorStrategyRegionCard assets={assets.items} />
              </Grid>

              <Grid size={{ xs: 12, lg: 6 }}>
                <InvestorStrategySectorCard assets={assets.items} />
              </Grid>

              <Grid size={{ xs: 12, lg: 6 }}>
                <InvestorStrategyOwnershipCard
                  assets={assets.items}
                  investor={investor}
                />
              </Grid>

              <Grid size={{ xs: 12, lg: 6 }}>
                <InvestorStrategyDealsCard
                  investor={investor}
                  strategy={strategy}
                />
              </Grid>
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorFundraisingCard
                funds={strategy.funds}
                href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
              />
            </Grid>

            <Grid size={{ xs: 12, lg: 8 }}>
              <InvestorStrategyFundsCard
                funds={strategy.funds}
                href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
              />
            </Grid>
          </>
        )}

        {!hasInformationCard && (
          <>
            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategyRegionCard assets={assets.items} />
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategySectorCard assets={assets.items} />
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorFundraisingCard funds={strategy.funds} />
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategyOwnershipCard
                assets={assets.items}
                investor={investor}
              />
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategyDealsCard
                investor={investor}
                strategy={strategy}
              />
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <InvestorStrategyFundsCard
                funds={strategy.funds}
                href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
              />
            </Grid>
          </>
        )}

        <Grid size={12}>
          <InvestorStrategyShareFeedbackAlert
            investorId={investor.id}
            investorName={investor.name}
            strategyId={strategy.id}
            strategyName={strategy.name}
          />
        </Grid>
      </Grid>
    </>
  )
}
