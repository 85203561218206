import { isTruthy } from '@gain/utils/common'
import { useIsXs } from '@gain/utils/responsive'
import { generatePath } from 'react-router-dom'

import {
  INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH,
  INVESTOR_STRATEGY_ADVISORS_PATH,
  INVESTOR_STRATEGY_ASSESSMENT_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_ENTRIES_PAGE_PATH,
  INVESTOR_STRATEGY_EXISTS_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  INVESTOR_STRATEGY_SUMMARY_PAGE_PATH,
  RouteInvestorStrategyTabs,
  useInvestorStrategyPageParams,
} from './route-strategy-path'

interface StrategyTabsOptions {
  hasAdvisors: boolean
}

export function useStrategyTabs({ hasAdvisors }: StrategyTabsOptions) {
  const params = useInvestorStrategyPageParams()
  const isXs = useIsXs()

  return [
    {
      label: 'Summary',
      path: generatePath(INVESTOR_STRATEGY_SUMMARY_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Summary,
    },
    {
      label: 'Portfolio',
      path: generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Portfolio,
    },
    {
      label: 'Funds',
      path: generatePath(INVESTOR_STRATEGY_FUNDS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Funds,
    },
    {
      label: 'Assessment',
      path: generatePath(INVESTOR_STRATEGY_ASSESSMENT_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Assessment,
    },
    !isXs && {
      label: 'Benchmarking',
      path: generatePath(INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Benchmarking,
    },
    {
      label: 'Entries',
      path: generatePath(INVESTOR_STRATEGY_ENTRIES_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Entries,
    },
    {
      label: 'Exists',
      path: generatePath(INVESTOR_STRATEGY_EXISTS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.Exists,
    },
    {
      label: 'Add-ons',
      path: generatePath(INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH, params),
      value: RouteInvestorStrategyTabs.AddOns,
    },
    hasAdvisors && {
      label: 'Advisors',
      path: generatePath(INVESTOR_STRATEGY_ADVISORS_PATH, params),
      value: RouteInvestorStrategyTabs.Advisors,
    },
  ].filter(isTruthy)
}
