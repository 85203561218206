import { useCurrentValuationRatios } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import { ListedSecurityListItem, ListedSecurityValuationRatios } from '@gain/rpc/app-model'
import { Option } from '@gain/rpc/shared-model'
import { useElementWidthEffect } from '@gain/utils/dom'
import { formatRatio } from '@gain/utils/number'
import { useIsXs } from '@gain/utils/responsive'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import * as echarts from 'echarts/core'
import { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import ChartTooltip from '../../../../common/chart/chart-tooltip'
import EChart from '../../../../common/echart/echart'
import SelectMenu from '../../../../common/select-menu/select-menu'
import { ValuationPeriod } from '../../../../features/valuation/asset-valuation-table'
import OutlierIcon from './outlier-icon'
import RatioSelect from './ratio-select'
import RatioTabs from './ratio-tabs'
import {
  findActiveRationOptionIndex,
  useRatioOptions,
  useRatioOptionsCurrentValuation,
} from './ratio-utils'
import useRatiosChartHover from './use-ratios-chart-hover'
import useRatiosChartOption from './use-ratios-chart-option'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4.5, 3, 2),
  position: 'relative',
}))

const StyledLegendContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
}))

export interface RatiosCardProps {
  listedSecurity: ListedSecurityListItem
  valuations: ListedSecurityValuationRatios[]
}

/**
 * RatiosCard allows the user to select an available ratio and shows the
 * datapoint for the selected ratio in a chart.
 */
export default function RatiosCard({ listedSecurity, valuations }: RatiosCardProps) {
  const isXs = useIsXs()
  const eChartsInstanceRef = useRef<echarts.ECharts | undefined>()
  const ratioOptions = useRatioOptions(valuations)
  const [activeTab, setActiveTab] = useState(findActiveRationOptionIndex(ratioOptions, valuations))
  const echartsOption = useRatiosChartOption(ratioOptions[activeTab]?.key, valuations)
  const [activeRatio, handleMouseMove, handleMouseOut] = useRatiosChartHover()

  // Force the cursor to be default since we cannot style it using ECharts
  const handleForceDefaultCursor = useCallback(() => {
    eChartsInstanceRef.current?.getZr().setCursorStyle('default')
  }, [])

  // Keep the ECharts reference up-to-date each time it (re-)initializes
  const handleInit = useCallback((eChartsInstance: echarts.ECharts) => {
    eChartsInstanceRef.current = eChartsInstance
  }, [])

  // Resize ECharts whenever the card size changes
  const cardRef = useRef<HTMLDivElement>(null)
  const resizeECharts = useCallback(() => {
    eChartsInstanceRef.current?.resize()
  }, [])
  useElementWidthEffect(cardRef, resizeECharts)

  const [valuationPeriod, setValuationPeriod] = useState<ValuationPeriod>('LastFiscalYear')
  const valuationPeriods = useMemo(() => {
    const date = new Date()
    const year = date.getFullYear()

    return new Array<Option<ValuationPeriod>>( // TODO: move this to a shared location with same declaration in valuation-card.tsx
      { label: `Last year (${year - 1})`, value: 'LastFiscalYear' },
      { label: 'LTM', value: 'LastTwelveMonths' },
      { label: `Current year (${year}E)`, value: 'CurrentFiscalYear' },
      { label: 'NTM', value: 'NextTwelveMonths' },
      { label: `Next year (${year + 1}E)`, value: 'NextFiscalYear' }
    )
  }, [])

  const swrValuationRatios = useCurrentValuationRatios({
    listedSecurityId: listedSecurity.id,
  })

  const selectedValuationRatios = swrValuationRatios.data?.find((valuation) => {
    const date = new Date()
    const currentYear = date.getFullYear()
    // TODO: put these compare functions straight into valuationPeriods array?
    if (valuationPeriod === 'CurrentFiscalYear') {
      return (
        valuation.financialResultPeriodicity === 'annual' &&
        valuation.financialResultFiscalYear === currentYear
      )
    }
    if (valuationPeriod === 'LastFiscalYear') {
      return (
        valuation.financialResultPeriodicity === 'annual' &&
        valuation.financialResultFiscalYear === currentYear - 1
      )
    }
    if (valuationPeriod === 'NextFiscalYear') {
      return (
        valuation.financialResultPeriodicity === 'annual' &&
        valuation.financialResultFiscalYear === currentYear + 1
      )
    }
    if (valuationPeriod === 'LastTwelveMonths') {
      return valuation.financialResultPeriodicity === 'lastTwelveMonths'
    }
    if (valuationPeriod === 'NextTwelveMonths') {
      return valuation.financialResultPeriodicity === 'nextTwelveMonths'
    }
    return false
  })

  // TODO: fix chip labels
  // TODO: fix tabs should always be enabled?
  const ratioOptionsCurrentValuation = useRatioOptionsCurrentValuation(selectedValuationRatios)

  return (
    <Card
      ref={cardRef}
      sx={{ pb: 0 }}>
      <CardHeader
        // TODO: this action should properly align vertically below title on mobile
        actions={
          <SelectMenu
            label={'Valuation period'}
            onChange={(value) => {
              setValuationPeriod(value)
            }}
            options={valuationPeriods}
            sx={{ mr: -0.5 }}
            value={valuationPeriod}
          />
        }
        title={'Valuation ratios'}
      />

      {/* Display tabs on all displays except mobile */}
      {!isXs && (
        <>
          <RatioTabs
            onChange={setActiveTab}
            options={ratioOptionsCurrentValuation}
            value={activeTab}
          />
          <Divider />
        </>
      )}

      {/* A dropdown select is shown on mobile to select the active ratio */}
      {isXs && (
        <RatioSelect
          onChange={setActiveTab}
          options={ratioOptionsCurrentValuation}
          value={activeTab}
        />
      )}

      <StyledCardContent>
        {activeRatio && (
          <ChartTooltip
            placement={activeRatio.placement}
            title={formatRatio(activeRatio.data.value)}
            disableInteractive
            open>
            <div
              style={{
                position: 'absolute',
                top: activeRatio?.rect.y,
                left: activeRatio?.rect.x,
                height: activeRatio?.rect.height,
                width: activeRatio?.rect.width,
                // ECharts uses transforms to position the plotted dots on the
                // chart. We simply apply the same transformation to this div
                // to make sure the containing tooltip can be rendered next to
                // the plotted dot that this tooltip belongs to.
                transform: `matrix(${activeRatio?.transform})`,
              }}></div>
          </ChartTooltip>
        )}
        <EChart
          onInit={handleInit}
          onMouseMove={handleMouseMove}
          // We cannot force the default cursor on a plotted line. To enforce
          // this behavior we set the cursor to default on every Zr mouse move
          // event.
          onMouseMoveZr={handleForceDefaultCursor}
          onMouseOut={handleMouseOut}
          option={echartsOption}
          style={{ height: 292 }}
          clearOnChange
        />
      </StyledCardContent>
      <Divider />
      <StyledLegendContainer>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={0.5}>
          <OutlierIcon />
          <Typography
            color={'text.secondary'}
            variant={'overline'}>
            Outlier
          </Typography>
        </Stack>
      </StyledLegendContainer>
    </Card>
  )
}
