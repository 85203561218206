import { InvestorFund } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import React from 'react'

import Card, { CardHeader } from '../../../../common/card/card'
import ViewMoreButton from '../../../../common/view-more-button'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'
import InvestorStrategyFundsTable from './investor-strategy-funds-table'

export interface InvestorStrategyFundsCardProps {
  href: string
  funds: InvestorFund[]
}

export default function InvestorStrategyFundsCard({ funds, href }: InvestorStrategyFundsCardProps) {
  const params = useInvestorStrategyPageParams()
  const [initial, rest] = useSplitList(funds, 4)

  return (
    <Card
      href={href}
      fullHeight>
      <CardHeader title={'Funds'} />
      <InvestorStrategyFundsTable rows={initial} />

      <ViewMoreButton
        amount={rest.length}
        href={generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_FUNDS_PAGE_PATH)}
        variant={'plus'}
      />
    </Card>
  )
}
