import OverflowContainer from '@gain/components/overflow-container'
import { InvestorStrategy } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
} from '@gain/rpc/shared-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import React from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import ExternalLink from '../../../../common/external-link'
import InfoButton from '../../../../common/info-button'
import KeyValueList, { KeyValueListItem } from '../../../../common/key-value/key-value-list'

export interface InvestorStrategyInformationCardProps {
  strategy: InvestorStrategy
  includeClassification?: boolean
  isSingleStrategy?: boolean
}

export default function InvestorStrategyInformationCard({
  strategy,
  includeClassification,
  isSingleStrategy,
}: InvestorStrategyInformationCardProps) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  const investmentTicketSizeRange = formatCurrencyRange(
    strategy.investmentTicketSizeMin,
    strategy.investmentTicketSizeMax,
    { dataCurrency: strategy.currency }
  )
  const evRange = formatCurrencyRange(strategy.evRangeMin, strategy.evRangeMax, {
    dataCurrency: strategy.currency,
  })
  const revenueRange = formatCurrencyRange(strategy.revenueRangeMin, strategy.revenueRangeMax, {
    dataCurrency: strategy.currency,
  })
  const ebitdaRange = formatCurrencyRange(strategy.ebitdaRangeMin, strategy.ebitdaRangeMax, {
    dataCurrency: strategy.currency,
  })

  const hasInvestmentPreferences = Boolean(
    investmentTicketSizeRange || evRange || revenueRange || ebitdaRange || strategy.source
  )

  const strategyClassifications = [
    strategy.primaryClassification,
    strategy.secondaryClassification,
    strategy.tertiaryClassification,
  ]
    .filter(Boolean)
    .map((classification) =>
      getLabelFromOption(INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS, classification)
    ) as string[]

  return (
    <Card fullHeight>
      {strategy.description && (
        <>
          <CardHeader
            endAdornment={
              <InfoButton
                color={'info'}
                dialogMessage={
                  isSingleStrategy
                    ? 'This investor has a single investment strategy. The information shown on this card is typically self-declared by investors and sourced from their website, but may also come from additional sources.'
                    : 'Investment preferences that have been stated by Investors. This information is typically sourced from Investor websites, but may also come from additional sources.'
                }
                dialogTitle={'Self-declared investor preferences'}
                label={isSingleStrategy ? 'Single strategy' : 'Self-declared'}
                size={'medium'}
                sx={{ ml: 1 }}
              />
            }
            title={'Strategy description'}
          />
          <CardContent>
            <Typography variant={'body2'}>{strategy.description}</Typography>
          </CardContent>

          {includeClassification && strategy.primaryClassification && (
            <CardContent sx={{ mt: 'auto' }}>
              <KeyValueList>
                <KeyValueListItem
                  label={'Strategy classification'}
                  value={
                    <OverflowContainer maxLines={1}>
                      {strategyClassifications.map((classification) => (
                        <Chip
                          key={classification}
                          label={classification}
                        />
                      ))}
                    </OverflowContainer>
                  }
                />
              </KeyValueList>
            </CardContent>
          )}
        </>
      )}

      {hasInvestmentPreferences && (
        <>
          <Divider
            sx={{
              py: 1,
              mx: 3,
              ...((!includeClassification || !strategy.primaryClassification) && {
                mt: 'auto',
              }),
            }}
          />

          <CardHeader title={'Investment preferences'} />
          <CardContent>
            <KeyValueList>
              {investmentTicketSizeRange && (
                <KeyValueListItem
                  label={'Investment ticket size'}
                  value={investmentTicketSizeRange}
                />
              )}

              {evRange && (
                <KeyValueListItem
                  label={'EV range'}
                  value={evRange}
                />
              )}

              {revenueRange && (
                <KeyValueListItem
                  label={'Revenue range'}
                  value={revenueRange}
                />
              )}

              {ebitdaRange && (
                <KeyValueListItem
                  label={'EBITDA range'}
                  value={ebitdaRange}
                />
              )}

              {strategy.source && (
                <KeyValueListItem
                  label={'Source'}
                  value={<ExternalLink href={strategy.source}>Link</ExternalLink>}
                />
              )}
            </KeyValueList>
          </CardContent>
        </>
      )}
    </Card>
  )
}
