import { BriefcaseMoneyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import { InvestorFund, InvestorFundListItem } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { investorFundExplanations } from '@gain/utils/investor-fund'
import { formatMultiple, formatPercentage } from '@gain/utils/number'
import { useIsXs } from '@gain/utils/responsive'
import { useOpenLink } from '@gain/utils/router'
import React from 'react'

import ContentLink from '../../common/content-link'
import {
  MobileListItem,
  MobileListItemIcon,
  MobileListItemSecondaryValue,
  MobileListItemText,
} from '../../common/list-mobile'
import Logo from '../../common/logo'
import { createVirtualTableColumns } from '../../common/virtual-table'
import { NameVirtualTableCell, NameVirtualTableHeaderCell } from '../../common/virtual-table/cells'
import FinancialValue from '../../features/financial/financial-value'
import InvestorContentLink from '../../features/investor/investor-content-link'
import {
  INVESTOR_FUND_DEFAULT_FILTERS,
  INVESTOR_FUND_FILTER_MAP,
  INVESTOR_FUND_FILTER_MENU,
} from '../../features/investor-fund/investor-fund-filter-bar'
import TooltipInvestorStrategy from '../../features/investor-strategy/investor-strategy-tooltip'
import ListView from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import { TableCellFinancialAmount } from '../investor/route-funds/table-cell-financial-amount'
import { generateInvestorStrategyPagePath } from '../investor-strategy'
import { generateInvestorPagePath, INVESTOR_PAGE_FUNDS_PATH } from '../utils'

type FundPerformanceAmountKey = keyof Pick<
  InvestorFundListItem,
  'netIrr' | 'grossIrr' | 'twr' | 'tvpi' | 'moic' | 'dpi' | 'rvpi'
>

type FundPerformanceDateKey = keyof Pick<
  InvestorFund,
  'netIrrDate' | 'grossIrrDate' | 'twrDate' | 'tvpiDate' | 'moicDate' | 'dpiDate' | 'rvpiDate'
>

interface FundPerformanceColumn {
  field: FundPerformanceAmountKey
  dateField: FundPerformanceDateKey
  label: string
  helperText: string
  width: number
  formatAmount: (value: number | null) => string
}

const fundPerformanceColumns = new Array<FundPerformanceColumn>(
  {
    field: 'netIrr',
    dateField: 'netIrrDate',
    label: 'Net IRR',
    helperText: investorFundExplanations.netIrr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'grossIrr',
    dateField: 'grossIrrDate',
    label: 'Gross IRR',
    helperText: investorFundExplanations.grossIrr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'twr',
    dateField: 'twrDate',
    label: 'TWR',
    helperText: investorFundExplanations.twr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'tvpi',
    dateField: 'tvpiDate',
    label: 'TVPI',
    helperText: investorFundExplanations.tvpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'moic',
    dateField: 'moicDate',
    label: 'MOIC',
    helperText: investorFundExplanations.moic,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'dpi',
    dateField: 'dpiDate',
    label: 'DPI',
    helperText: investorFundExplanations.dpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'rvpi',
    dateField: 'rvpiDate',
    label: 'RVPI',
    helperText: investorFundExplanations.rvpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  }
)

const columns = createVirtualTableColumns<InvestorFundListItem>(
  {
    field: 'name',
    headerName: 'Fund',
    align: 'left',
    width: 266,
    sticky: true,
    flex: 1,
    renderHeader: NameVirtualTableHeaderCell,
    renderCell: ({ row }) => (
      <NameVirtualTableCell
        description={[row.investorName, row.strategyName].filter(isTruthy).join(' - ')}
        name={row.name}
      />
    ),
  },
  {
    field: 'vintageYear',
    headerName: 'Vintage date',
    align: 'right',
    width: 130,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'fundSizeEur',
    headerName: 'Fund size',
    align: 'right',
    width: 128,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'investorId',
    headerName: 'Investor',
    align: 'left',
    width: 272,
    renderCell: ({ row }) => (
      <InvestorContentLink
        id={row.investorId}
        logoFileUrl={row.investorLogoFileUrl}
        name={row.investorName}
      />
    ),
  },
  {
    field: 'strategyId',
    headerName: 'Strategy',
    align: 'left',
    width: 272,
    renderCell: ({ row }) => {
      if (row.strategyId && row.strategyName) {
        return (
          <TooltipInvestorStrategy id={row.strategyId}>
            <ContentLink
              href={generateInvestorStrategyPagePath({
                investorId: row.investorId,
                strategyId: row.strategyId,
                strategyName: row.strategyName,
                investorName: row.investorName,
              })}
              label={row.strategyName}
            />
          </TooltipInvestorStrategy>
        )
      }

      return <>-</>
    },
  },
  ...fundPerformanceColumns.map((config) => ({
    field: config.field,
    headerName: config.label,
    width: config.width,
    headerExplainer: config.helperText,
    renderCell: ({ row }) => (
      <TableCellFinancialAmount
        amount={row[config.field]}
        date={row[config.dateField]}
        formatAmount={config.formatAmount}
      />
    ),
  }))
)

export default function InvestorListPageFundTab() {
  const isXs = useIsXs()
  const openLink = useOpenLink()
  useTrackPageView('investor', { tab: 'funds', requireTab: true, list: true })

  return (
    <>
      <Head>
        <title>Investors - Funds</title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={'Funds'}
          variant={'list'}
        />
      )}
      <ListView
        addFilterMenu={INVESTOR_FUND_FILTER_MENU}
        defaultFilterModel={INVESTOR_FUND_DEFAULT_FILTERS}
        defaultSort={[listSort<InvestorFundListItem>('vintageDate', 'desc')]}
        filterBarSearchLabel={'Fund name'}
        filterBarSearchPlaceholder={'E.g. Compass fund'}
        filterConfigMap={INVESTOR_FUND_FILTER_MAP}
        inline={isXs}
        method={'data.listInvestorFunds'}
        sm={{
          variant: 'virtual-table',
          VariantProps: {
            columns: columns,
            onRowClick({ row }, event) {
              openLink(
                generateInvestorPagePath(
                  {
                    investorId: row.investorId,
                    investorName: row.investorName,
                  },
                  INVESTOR_PAGE_FUNDS_PATH
                ),
                event
              )
            },
          },
        }}
        xs={{
          variant: 'list',
          VariantProps: {
            headerProps: {
              title: 'Name',
              secondaryTitle: 'Latest fund size',
            },
            renderListItem: (item) => (
              <MobileListItem
                key={item.strategyId}
                disableDivider>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<BriefcaseMoneyIcon />}
                    size={40}
                    src={item.investorLogoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.strategyName}
                  secondary={item.investorName}
                />
                <MobileListItemSecondaryValue>
                  <FinancialValue amount={item.fundSizeEur} />
                </MobileListItemSecondaryValue>
              </MobileListItem>
            ),
          },
        }}
        disableOrFilter
      />
    </>
  )
}
