import Head from '@gain/modules/head'
import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import React from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import HorizontalTable from '../../../common/horizontal-table'
import { generateInvestorStrategyPagePath } from '../../investor-strategy'
import { useStrategiesGroups } from './route-strategies-hooks'

const StyledContentLink = styled(ContentLink)({
  maxWidth: '100%', // prevent text from overflowing
})

export interface RouteStrategiesProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
}

const CELL_PADDING_MD_UP = 3
const CELL_PADDING_XS = 1

const VALUE_CELL_MIN_WIDTH_SM_UP = 208 + 8 * CELL_PADDING_MD_UP * 2
const VALUE_CELL_MIN_WIDTH_XS = 72 + 8 * CELL_PADDING_XS * 2

const LABEL_CELL_MIN_WIDTH_SM_UP = 192
const LABEL_CELL_MIN_WIDTH_XS = 144

export default function RouteStrategies({ investor, strategies }: RouteStrategiesProps) {
  const rowGroups = useStrategiesGroups(investor)

  return (
    <Grid
      spacing={2}
      container>
      <Grid
        xs={12}
        item>
        <Head>
          <title>{investor.name} - Strategies</title>
        </Head>
        <Card sx={{ pb: 0 }}>
          <CardHeader title={'Strategies'} />
          <HorizontalTable
            label={'Strategy name'}
            rowConfig={{
              getHeaderLabel: (row) => (
                <StyledContentLink
                  href={generateInvestorStrategyPagePath({
                    investorId: investor.id,
                    investorName: investor.name,
                    strategyId: row.id,
                    strategyName: row.name,
                  })}
                  label={row.name}
                  labelTypographyProps={{
                    fontWeight: 'bold',
                  }}
                />
              ),
              getRowId: (row) => row.id,
              getRowSizing: (isXs) => ({
                labelCellWidth: isXs ? LABEL_CELL_MIN_WIDTH_XS : LABEL_CELL_MIN_WIDTH_SM_UP,
                minValueCellWidth: isXs ? VALUE_CELL_MIN_WIDTH_XS : VALUE_CELL_MIN_WIDTH_SM_UP,
                maxValueCellWidth: 256,
                valueCellHeight: 36,
                cellPaddingX: isXs ? CELL_PADDING_XS : CELL_PADDING_MD_UP,
                leftCellPaddingLeft: isXs ? 2 : CELL_PADDING_MD_UP,
              }),
              hideRowWhenEmpty: false,
            }}
            rowGroups={rowGroups}
            rows={strategies}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
