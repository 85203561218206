import Head from '@gain/modules/head'
import { RelatedDealAdvisor } from '@gain/rpc/app-model'
import isEmpty from 'lodash/isEmpty'
import { stringify } from 'query-string'
import React from 'react'
import { DelimitedNumericArrayParam } from 'serialize-query-params'
import { encodeQueryParams } from 'use-query-params'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { calculateVisibleRows } from '../../../common/visible-rows'
import RelatedDealAdvisorsTable from '../../../features/deal-advisor'
import { INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH } from '../../investor-strategy-advisor-deals'
import {
  generateInvestorStrategyPagePath,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'

interface RouteAdvisorsProps {
  dealAdvisors: RelatedDealAdvisor[]
  availableHeight: number
}

export default function RouteAdvisors({ dealAdvisors, availableHeight }: RouteAdvisorsProps) {
  const params = useInvestorStrategyPageParams()

  const nrOfVisibleRows = calculateVisibleRows(availableHeight)
  const generateInvestorAdvisorDealsPath = (advisor: RelatedDealAdvisor) => {
    const path = generateInvestorStrategyPagePath(
      { ...params, advisorName: advisor.advisorName, advisorId: `${advisor.advisorId}` },
      INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH
    )

    // Currently we do not have "buyerAdvisorIds" or "sellerAdvisorIds" in the deal model,
    // hence we pass the deal ids explicitly.
    const dealIds = encodeQueryParams(
      { dealIds: DelimitedNumericArrayParam },
      { dealIds: advisor.dealIds }
    )
    return `${path}?${stringify(dealIds)}`
  }

  return (
    <>
      <Head>
        <title>
          {params.investorName} - {params.strategyName} - Advisors
        </title>
      </Head>

      <Card sx={{ pb: 1, width: '100%', minHeight: 256 }}>
        <CardHeader title={'Deal advisors'} />
        {!isEmpty(dealAdvisors) ? (
          <RelatedDealAdvisorsTable
            dealsPath={generateInvestorAdvisorDealsPath}
            items={dealAdvisors ?? []}
            nrOfVisibleRows={nrOfVisibleRows}
          />
        ) : (
          <CardContentEmpty message={'There are no advisors associated with this investor'} />
        )}
      </Card>
    </>
  )
}
