import {
  BriefcaseIcon,
  CompanyIcon,
  FlagIcon,
  GlobeIcon,
  LeafIcon,
  MoneyBagIcon,
  ShuffleIcon,
} from '@gain/components/icons'
import { FilterableInvestorStrategyListItem } from '@gain/rpc/app-model'
import { INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS } from '@gain/rpc/shared-model'
import { ratingEsgMap } from '@gain/utils/investment-criteria'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  checkboxList,
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  investor,
  option,
  rangeCurrency,
  rangeNumber,
  rangeYear,
  rating,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const INVESTOR_STRATEGY_FILTER_FIELDS = [
  'investorId',
  'assetsFiltered',
  'assetRegions',
  'assetSubsectors',

  'asset.tagIds',

  'asset.ebitdaEur',
  'assetEbitdaMedianEur',
  'dryPowderMinEur',

  'classifications',

  'fundsRaisedLastFiveYearsEur',
  'latestFundSizeEur',
  'latestFundVintageDateYear',

  'dealsTotalLastFiveYears',
  'dealsAddOnsTotalLastFiveYears',
  'dealsEntriesTotalLastFiveYears',
  'dealsExitTotalLastFiveYears',

  'assetsMedianMarketSegmentRatingOverall',
  'assetsMedianMarketSegmentRatingEnvironmental',
  'assetsMedianMarketSegmentRatingSocial',
] as const

export type InvestorStrategyFilterField = (typeof INVESTOR_STRATEGY_FILTER_FIELDS)[number]

export const INVESTOR_STRATEGY_FILTER_MAP = createFilterMap<
  FilterableInvestorStrategyListItem,
  typeof INVESTOR_STRATEGY_FILTER_FIELDS
>(
  investor('investorId', 'Investor'),
  rangeNumber('assetsFiltered', 'Number of companies', {
    icon: CompanyIcon,
    hint: 'The number of companies of the investor portfolio that must match the region, sector and EBITDA range filter.',
  }),
  region('assetRegions', 'Active in region', { icon: GlobeIcon }),
  sector('assetSubsectors', 'Active in sector'),

  tag('asset.tagIds', 'Tags'),

  rangeCurrency('asset.ebitdaEur', 'EBITDA range'),
  rangeCurrency('assetEbitdaMedianEur', 'Median EBITDA'),
  rangeCurrency('dryPowderMinEur', 'Drypowder estimate', { maxFilterField: 'dryPowderMaxEur' }),

  checkboxList(
    'classifications',
    'Strategy classification',
    INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS.map((classification) =>
      option(classification.label, classification.value)
    )
  ),

  rangeCurrency('fundsRaisedLastFiveYearsEur', 'Fundraising (L5Y)', { icon: MoneyBagIcon }),
  rangeCurrency('latestFundSizeEur', 'Latest fund size'),
  rangeYear('latestFundVintageDateYear', 'Latest fund vintage date'),

  rangeNumber('dealsTotalLastFiveYears', 'Deals (L5Y)'),
  rangeNumber('dealsAddOnsTotalLastFiveYears', 'Add-ons (L5Y)'),
  rangeNumber('dealsEntriesTotalLastFiveYears', 'Entries (L5Y)'),
  rangeNumber('dealsExitTotalLastFiveYears', 'Exists (L5Y)'),

  rating('assetsMedianMarketSegmentRatingOverall', ratingEsgMap.ratingOverall),
  rating('assetsMedianMarketSegmentRatingEnvironmental', ratingEsgMap.ratingEnvironmental),
  rating('assetsMedianMarketSegmentRatingSocial', ratingEsgMap.ratingSocial)
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const INVESTOR_STRATEGY_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<FilterableInvestorStrategyListItem, InvestorStrategyFilterField>
> = [
  INVESTOR_STRATEGY_FILTER_MAP.investorId,
  INVESTOR_STRATEGY_FILTER_MAP.assetsFiltered,
  INVESTOR_STRATEGY_FILTER_MAP.classifications,
  INVESTOR_STRATEGY_FILTER_MAP.fundsRaisedLastFiveYearsEur,

  dropdownMenuGroup('latestFund', 'Latest fund', {
    icon: FlagIcon,
    children: [
      INVESTOR_STRATEGY_FILTER_MAP.latestFundSizeEur,
      INVESTOR_STRATEGY_FILTER_MAP.latestFundVintageDateYear,
      INVESTOR_STRATEGY_FILTER_MAP.dryPowderMinEur,
    ],
  }),
  dropdownMenuGroup('portfolio', 'Portfolio', {
    icon: BriefcaseIcon,
    children: [
      INVESTOR_STRATEGY_FILTER_MAP['asset.ebitdaEur'],
      INVESTOR_STRATEGY_FILTER_MAP.assetEbitdaMedianEur,
      INVESTOR_STRATEGY_FILTER_MAP.assetRegions,
      INVESTOR_STRATEGY_FILTER_MAP.assetSubsectors,
      INVESTOR_STRATEGY_FILTER_MAP['asset.tagIds'],
    ],
  }),
  dropdownMenuGroup('deals', 'Deals', {
    icon: ShuffleIcon,
    children: [
      INVESTOR_STRATEGY_FILTER_MAP.dealsTotalLastFiveYears,
      INVESTOR_STRATEGY_FILTER_MAP.dealsEntriesTotalLastFiveYears,
      INVESTOR_STRATEGY_FILTER_MAP.dealsExitTotalLastFiveYears,
      INVESTOR_STRATEGY_FILTER_MAP.dealsAddOnsTotalLastFiveYears,
    ],
  }),
  dropdownMenuGroup('esg', 'ESG', {
    icon: LeafIcon,
    children: [
      INVESTOR_STRATEGY_FILTER_MAP.assetsMedianMarketSegmentRatingOverall,
      INVESTOR_STRATEGY_FILTER_MAP.assetsMedianMarketSegmentRatingEnvironmental,
      INVESTOR_STRATEGY_FILTER_MAP.assetsMedianMarketSegmentRatingSocial,
    ],
  }),
]

export const INVESTOR_STRATEGY_DEFAULT_FILTERS: FilterModel<
  FilterableInvestorStrategyListItem,
  InvestorStrategyFilterField
> = [
  filterValueGroup(filterValueItem('assetsFiltered')),
  filterValueGroup(filterValueItem('dealsTotalLastFiveYears')),
  filterValueGroup(filterValueItem('fundsRaisedLastFiveYearsEur')),
  filterValueGroup(filterValueItem('latestFundSizeEur')),
  filterValueGroup(filterValueItem('classifications')),
  filterValueGroup(filterValueItem('assetRegions')),
  filterValueGroup(filterValueItem('assetSubsectors')),
]
