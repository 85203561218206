import Tooltip from '@gain/components/tooltip'
import { RenderCellParams } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import React from 'react'

export function InvestorListItemAssetsTotalTableCell({
  row: { assetsTotal, assetsFiltered },
}: RenderCellParams<
  {
    assetsTotal: number
    assetsFiltered: number
  },
  'assetsTotal'
>) {
  const hasFiltered = assetsFiltered < assetsTotal
  const label = hasFiltered ? `${assetsFiltered}/${assetsTotal}` : assetsTotal
  const tooltip = hasFiltered
    ? `${assetsFiltered} of ${assetsTotal} assets match your filter settings.`
    : ''
  return (
    <Tooltip
      title={tooltip}
      variant={'preview'}
      disableInteractive>
      <Chip
        color={'info'}
        label={label}
        variant={'outlined'}
      />
    </Tooltip>
  )
}
