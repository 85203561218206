import { AssetList, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import React from 'react'

import Card from '../../../../common/card/card'
import CardMetrics, { createMetrics } from '../../../../common/metrics/metrics-card'
import { DRY_POWDER_EXPLAINER } from '../../../investor/investor-profile-util'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_ENTRIES_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'
import {
  useInvestorStrategyAddOnCount,
  useInvestorStrategyDealCount,
  useInvestorStrategyDryPowder,
} from './investor-strategy-metrics-card-hooks'

export interface InvestorMetricsCardProps {
  investor: Investor
  strategy: InvestorStrategy
  assets: AssetList
}

export default function InvestorStrategyMetricsCard({
  investor,
  strategy,
  assets,
}: InvestorMetricsCardProps) {
  const strategyPageParams = useInvestorStrategyPageParams()
  const dealCount = useInvestorStrategyDealCount(investor, strategy)
  const addOnCount = useInvestorStrategyAddOnCount(assets)
  const dryPowderRange = useInvestorStrategyDryPowder(strategy)
  const formatCurrency = useFormatCurrencyCallback()

  const latestFund = strategy.funds.find((fund) => fund.id === strategy.latestFundId)

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics
        metrics={createMetrics(
          {
            href: generateInvestorStrategyPagePath(
              strategyPageParams,
              INVESTOR_STRATEGY_ASSETS_PAGE_PATH
            ),
            label: 'Companies',
            value: assets.counts.filtered,
          },
          typeof strategy.fundsRaisedLastFiveYearsEur === 'number' &&
            strategy.fundsRaisedLastFiveYearsEur > 0 && {
              label: 'Fundraising (L5Y)',
              value: formatCurrency(strategy.fundsRaisedLastFiveYearsEur),
            },
          latestFund &&
            latestFund.fundSizeEur !== null && {
              label: 'Latest fund size',
              value: formatCurrency(latestFund.fundSizeEur),
            },
          dryPowderRange !== null && {
            label: 'Drypowder est.',
            value: dryPowderRange,
            explainer: DRY_POWDER_EXPLAINER,
          },
          {
            label: 'Platform deals (L5Y)',
            href: generateInvestorStrategyPagePath(
              strategyPageParams,
              INVESTOR_STRATEGY_ENTRIES_PAGE_PATH
            ),
            value: dealCount || 0,
          },
          {
            label: 'Add-ons (L5Y)',
            href: generateInvestorStrategyPagePath(
              strategyPageParams,
              INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH
            ),
            value: addOnCount || 0,
          }
        )}
      />
    </Card>
  )
}
