import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import DialogHeader from '../../../../common/dialog-header'
import { generateInvestorStrategyPagePath } from '../../route-strategy-path'

const SNACKBAR_KEY = 'investor-strategy-feedback-shared'

function createExternalLink(path: string) {
  return [window.location.protocol, [window.location.host, path].join('')]
    .filter(Boolean)
    .join('//')
}

export interface ShareFeedbackDialogProps {
  investorId: number
  investorName: string
  strategyId: number
  strategyName: string
  open: boolean
  onClose: () => void
}

export default function InvestorStrategyShareFeedbackDialog({
  investorId,
  investorName,
  strategyId,
  strategyName,
  open,
  onClose,
}: ShareFeedbackDialogProps) {
  const fetcher = useRpcClient()
  const [error, setError] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      feedback: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        feedback: yup.string().required('Feedback field is required'),
      })
    ),
  })

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true)
    setError(null)
    try {
      const strategyUrl = createExternalLink(
        history.createHref({
          pathname: generateInvestorStrategyPagePath({
            investorName,
            investorId,
            strategyId,
            strategyName,
          }),
        })
      )

      await fetcher({
        method: 'account.createTicket',
        params: {
          type: TicketType.ShareInvestorStrategyFeedback,
          body: data.feedback,
          attributes: [
            { type: TicketAttributeType.InvestorName, value: investorName },
            { type: TicketAttributeType.InvestorStrategyName, value: strategyName },
            { type: TicketAttributeType.GainProInvestorUrl, value: strategyUrl },
          ],
        },
      })

      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            id={SNACKBAR_KEY}
            message={'Your feedback has been received. We will get back to you shortly.'}
          />
        ),
        preventDuplicate: true,
      })
      onClose()
    } catch {
      setError('An error occurred while submitting the form, please try again later.')
    }

    setSubmitting(false)
  })

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => reset(),
      }}
      fullWidth>
      <form
        onSubmit={onSubmit}
        noValidate>
        <DialogHeader
          onCloseClick={onClose}
          title={'Give feedback on investor profile'}
        />

        <DialogContent>
          <Grid
            rowSpacing={2}
            container>
            {error && (
              <Grid size={12}>
                <Alert severity={'error'}>{error}</Alert>
              </Grid>
            )}
            <Grid size={12}>
              <TextField
                label={'Investor name'}
                name={'investorName'}
                value={investorName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                label={'Strategy name'}
                name={'strategyName'}
                value={strategyName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                error={!!errors.feedback}
                helperText={errors.feedback?.message}
                label={'Feedback'}
                maxRows={10}
                minRows={10}
                name={'feedback'}
                placeholder={'Please enter your feedback here.'}
                slotProps={{
                  htmlInput: { ...register('feedback') },
                }}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color={'primary'}
            disabled={!isDirty || !isValid}
            loading={submitting}
            type={'submit'}
            variant={'contained'}
            fullWidth>
            Send
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
