import { ArrowUpRightIcon } from '@gain/components/icons'
import { addHttpsIfMissing } from '@gain/utils/common'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'

export interface ExternalLinkProps {
  href: string
}

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,

  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& svg': {
    fontSize: 16,
  },
})

export default function ExternalLink({ children, href }: PropsWithChildren<ExternalLinkProps>) {
  return (
    <StyledLink
      color={'primary'}
      href={addHttpsIfMissing(href)}
      rel={'noreferrer noopener'}
      target={'_blank'}
      variant={'body2'}>
      <span>{children}</span>
      <ArrowUpRightIcon fontSize={'inherit'} />
    </StyledLink>
  )
}
